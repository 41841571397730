<template>
    <AppHeaderDropdown right no-caret variant="linkb-dropdown">
        <template slot="header">
            <b-card class="p-1 m-0" no-body style="border:none;">
                <table>
                    <tr>
                        <td v-if="screenWidth>992">
                            <div class="mr-1">{{ name }}</div>
                            <div class="mr-1" style="color: #7f8c8d; font-size: small">{{ email }}</div>
                        </td>
                        <td>
                            <img style="width:40px; height:40px;"
                                 :src="profilePicture"
                                 class="img-avatar mx-1"
                                 alt="admin@bootstrapmaster.com"/>
                        </td>
                    </tr>

                </table>
            </b-card>

        </template>
        <template slot="dropdown">
            <!--<b-dropdown-header tag="div" class="text-center"><strong>Account</strong></b-dropdown-header>
            <b-dropdown-item><i class="fa fa-bell-o" /> Updates
              <b-badge variant="info">{{ itemsCount }}</b-badge>
            </b-dropdown-item>
            <b-dropdown-item><i class="fa fa-envelope-o" /> Messages
              <b-badge variant="success">{{ itemsCount }}</b-badge>
            </b-dropdown-item>
            <b-dropdown-item><i class="fa fa-tasks" /> Tasks
              <b-badge variant="danger">{{ itemsCount }}</b-badge>
            </b-dropdown-item>
            <b-dropdown-item><i class="fa fa-comments" /> Comments
              <b-badge variant="warning">{{ itemsCount }}</b-badge>
            </b-dropdown-item>-->
            <b-dropdown-header v-if="screenWidth<=992"
                               tag="div"
                               class="text-center">
                <strong>{{ name }} <br> {{ email }}</strong>
            </b-dropdown-header>
<!--            <b-dropdown-item :to="{ name: 'Profile Overview'}"><i class="fa fa-user"/> Profile</b-dropdown-item>-->
            <b-dropdown-item @click="userSettingsRedirect"><fa-icon :icon="['fas', 'wrench']" style="cursor: pointer;"/> Profile Settings</b-dropdown-item>
            <b-dropdown-item @click="logoutMethod"><fa-icon :icon="['fas', 'lock']" style="cursor: pointer;"/> Logout</b-dropdown-item>
        </template>
    </AppHeaderDropdown>
</template>

<script>
import {HeaderDropdown as AppHeaderDropdown} from '@coreui/vue'
import authStore from "@/store/authStore";

export default {
    name: 'DefaultHeaderDropdownAccnt',
    components: {
        AppHeaderDropdown
    },
    async created() {
        await this.getProfile();
    },
    data: () => {
        return {
            itemsCount: 42,
            name: '',
            email: '',
            profilePicture: ''
        }
    },
    methods: {
        logoutMethod() {
            authStore.getters.getUserManager.logout();
        },
        userSettingsRedirect() {
            window.location = authStore.getters.getUserManager.get_userSettingsUri();
        },
        async getProfile() {
            let user = await authStore.dispatch("getUser");
            if (user.firstname || user.lastname) {
                this.name =  user.firstname + " " + user.lastname;
            } else {
                this.name = user.name;
            }
            this.email = user.email;
            this.profilePicture = user.profilePicture;
        }
    },
    computed: {
        screenWidth() {
            return screen.width;
        }
    }
}
</script>
